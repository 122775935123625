import React from "react"

import Seo from "../components/Seo"
import { MarginContainer } from "./agb"

const ImpressumPage: React.FC<{}> = () => {
  return (
    <MarginContainer>
      <Seo title="Impressum" />
      <h2>IMPRESSUM</h2>
      <h3>WEBSEITENBETREIBER</h3>
      <p>AtlasAvia GmbH</p>
      <h3>INFORMATIONEN ÜBER DAS UNTERNEHMEN</h3>
      <p>
        AtlasAvia GmbH
        <br />
        Nordendstr. 82-84
        <br />
        64546 Mörfelden-Walldorf
        <br />
        info@hochwerben.de
        <br />
        06105 / 9777704
        <br />
      </p>
      <h3>INFORMATIONEN ÜBER DIE UNTERNEHMENSREGISTRIERUNG</h3>
      <p>
        Registerart: Handelsregister
        <br />
        Register / Ort: Amtsgericht Darmstadt
        <br />
        Registernummer: HRB 53375
        <br /> Angehörige Kammer: IHK Darmstadt
        <br /> Umsatzsteuer ID: DE 113827450
      </p>
      <p>
        Informationen zur Online-Streitbeilegung: Die EU-Kommission hat eine
        Internetplattform zur Online-Beilegung von Streitigkeiten (sog.
        „OS-Plattform“) geschaffen. Die OS-Plattform dient als Anlaufstelle zur
        außergerichtlichen Beilegung von Streitigkeiten betreffend vertragliche
        Verpflichtungen, die aus Online-Kaufverträgen erwachsen. Sie können die
        OS-Plattform unter dem folgenden Link erreichen:
        http://ec.euopa.eu/consumers/odr „Hinweis gemäß § 36
        Verbraucherstreitbeilegungsgesetz (VSBG).
      </p>
    </MarginContainer>
  )
}

export default ImpressumPage
